<template>
  <v-card class="background" @click="clickBack()">
    <v-dialog
      v-model="dialog"
      light
      persistent
      max-width="400"
      class="elevation-5"
    >
      <view-access-control
        @start="start($event)"
        @cancel="cancel()"
      ></view-access-control>
    </v-dialog>
    <div v-if="dialogCamera">
      <v-dialog v-model="dialogCamera" max-width="700">
        <v-card> <v-col> </v-col></v-card>
      </v-dialog>
    </div>
    <div>
      <s-scanner-qr
        :config="configScann"
        autofocus
        return-object
        :readonly="false"
        @onValue="onValue($event)"
        @onData="onData($event)"
      ></s-scanner-qr>
      <v-divider class="mx-4"></v-divider>
      <v-card
        v-if="person != null"
        class="d-flex justify-center"
        rounded=""
        :color="person.color"
      >
        <div v-if="person.MahMessage" class="d-flex justify-center">
          <p
            class="text-center d-none d-sm-flex"
            style="padding: 6px; color: white; font-size: 48px"
          >
            <v-row justify="center"
              ><b>{{ person.MahMessage }}</b></v-row
            >
          </p>
          <p
            class="text-center d-flex d-sm-none"
            style="padding: 6px; color: white; font-size: 32px"
          >
            <v-row justify="center"
              ><b>{{ person.MahMessage }}</b></v-row
            >
          </p>
        </div>
      </v-card>
      <v-card>
        <v-card-title
          class="justify-center font-weight-light pt-5"
          style="font-size: 1.5rem !important; al"
        >
          <h4>{{ pv.BscCompany + ' - ' + pv.TypeRouteName }}</h4>
        </v-card-title>
        <v-card-subtitle
          class="text-center"
          style="font-size: 1.125rem !important; color: #9e9e9e !important"
          >Contro Bus - Placa {{ pv.BscLicencePlate }}</v-card-subtitle
        >
        <v-card-subtitle
          v-if="existBusTurn"
          class="text-center"
          style="font-size: 1.125rem !important; color: #9e9e9e !important"
        >
          <v-btn color="primary" @click="endBusTurn()">Finalizar Turno</v-btn>
        </v-card-subtitle>
        <v-card-title
          class="justify-center font-weight-light pt-5"
          style="font-size: 1.5rem !important; al"
        >
          <h4>Capacidad - {{ capacity }}</h4>
        </v-card-title>
        <v-divider class="mx-4"></v-divider>

        <v-card-text>
          <p
            v-if="person.NtpName"
            style="font-size: 32px !important"
            class="text-center text-h4 font-weight-light"
          >
            {{
              person.NtpPaternalSurname +
              " " +
              person.NtpMaternalSurname +
              " " +
              person.NtpName
            }}
          </p>
          <p
            v-else
            style="font-size: 32px !important"
            class="text-center text-h4 font-weight-light"
          >
            CONTROL DE BUS
          </p>
        </v-card-text>
        <v-row>
          <v-col
            cols="8"
            offset="2"
            offset-sm="5"
            offset-xl="2"
            offset-md="3"
            offset-lg="5"
          >
            <div class="avatar-wrapper">
              <div class="v-image v-responsive user-avatar theme--light">
                <v-col style="padding: 10px">
                  <v-img
                    class="text-center"
                    style="border-radius: 90px; width: 189px"
                    v-if="image == ''"
                    src="./../../../public/static/User.png"
                  ></v-img>
                  <v-img
                    v-else
                    class="text-center"
                    style="border-radius: 90px; width: 189px"
                    :src="image"
                  ></v-img>
                </v-col>
                <div class="v-responsive__content" style="width: 179px"></div>
              </div>
            </div>
          </v-col>
          <v-col cols="12">
            <p
              style="
                font-size: 15px !important;
                color: var(--v-anchor-base);
                line-height: 1;
                text-align: center;
              "
            >
              {{ person.PrsDocumentNumber }}
            </p>
            <p
              class="user-work greyTint--text ma-0"
              style="
                font-size: 15px !important;
                color: var(--v-warning-base);
                line-height: 1;
                text-align: center;
              "
            >
              {{ person.PstName }}
            </p>
            <p
              v-if="person.AreName"
              style="
                font-size: 15px !important;
                color: var(--v-anchor-base);
                line-height: 1;
                text-align: center;
              "
            >
              {{ person.AreName }}
            </p>
          </v-col>
        </v-row>
      </v-card>
    </div>
   
  </v-card>
</template>


<script>
import ViewAccessControl from "@/views/HumanResource/BusMonitoring/AccesControl";
import ControlAccessService from "../../services/Management/ControlAccess";
import HelperService from "./../../services/HelperService";
import _sHmnBusTurnService from "@/services/HumanResource/HmnBusTurnService";
import _smonitoringBusService from '@/services/Management/MonitoringBusService.js';


export default {
  name: "TimeRealReserve",
  components: { ViewAccessControl },
  props: {},
  data: () => ({
    dialogCamera: false,
    pv: null,
    image: "",
    document: "",
    dialog: false,
    timeRefresh: 0,
    pv: {},
    configScann: {},
    person: {
      NtpFullName: "CONTROL DE ACCESO",
      PstName: "",
      PrsDocumentNumber: "",
      color: "white",
    },
    busTurn: {},
    existBusTurn: false,
    registerPersonBus: {},
    capacity: 0,
    isActiveRoute : false,
    isActiveTurn : true,
    latitud: "",
    longitud: "",
  }),

  watch: {
    capacity() {
      if(this.busTurn != null)
      {
        if(this.capacity == 0)
        {
          this.$fun.alert("El bus ya esta lleno", "warning");
        }
      }
    },

    isActiveRoute()
    {
      this.initialize();
    },

    isActiveTurn()
    {
      this.initialize();
    }

  },

 /*  computed: {
    isActiveRoute() {
      var isActiveRoute = false;
      let obj = {
        BscDniDriver :  this.$fun.getUserInfoLogin().toString()
      }
      _smonitoringBusService
      .search(obj, this.$fun.getUserID())
      .then(resp => {
        if(resp.status == 200)
        {
          
          if(resp.data != null)
          {
            console.log('ruta del conductor activa ', resp.data);
            this.capacity = resp.data.BscCapacity;
            this.pv = resp.data;
            isActiveRoute = true;
          }
          
        }
      })
      return isActiveRoute;
    }
  }, */

  methods: {
    endBusTurn()
    {
      this.$fun.alert("¿Seguro de finalizar turno?", "question")
      .then(r => {
        if(r.value)
        {
          _sHmnBusTurnService
          .save(this.busTurn, this.$fun.getUserID())
          .then(resp => {
            if(resp.status == 200)
            {
              this.$fun.alert("Turno finalizado correctamente", "success");
              this.existBusTurn = false;
              
            }
          })
        }
      })
      
    },


    createBusTurn(item)
    {
      let obj = {
        BscDniDriver : this.$fun.getUserInfoLogin()
      }
      _sHmnBusTurnService
      .busturnactive(obj, this.$fun.getUserID())
      .then(resp => {
        if(resp.status == 200){
          if(resp.data == null)
          {
            console.log('datos de la ruta del bus ', this.pv);
            let busTurn = {};
            busTurn.BscID = this.pv.BscID;
            busTurn.SecStatus = 1;
            busTurn.UsrCreateID = this.$fun.getUserID();
            busTurn.UsrUpdateID = this.$fun.getUserID();
            _sHmnBusTurnService
            .save(busTurn, this.$fun.getUserID())
            .then(resp => {
              if(resp.status == 200)
              {
                this.$fun.alert("Turno iniciado correctamente", "success");
                this.existBusTurn = true;
              }
            })
          }
        }
      })
    },

    getLocation() {
      if (navigator.geolocation) {

        navigator.geolocation.getCurrentPosition((position) => {
          this.latitud = position.coords.latitude;
          this.longitud = position.coords.longitude;
          this.url = "https://www.google.com/maps/@"+this.latitud+','+this.longitud+',15z';

          console.log('aaaaaaaaaaaa ', this.latitud);
          console.log('aaaaaaaaaaaa ', this.longitud);
        }, function(msg) {
          console.log('errorrr');
          console.log(mgs);
        })
      }
    },

    getQuantityPersonEntryByTurn()
    {
      if(this.busTurn != null)
      {
        _sHmnBusTurnService
        .quantitypersonbyturnactive(this.busTurn, this.$fun.getUserID())
        .then(resp => {
          if(resp.status == 200)
          {
            
            this.capacity = parseInt(this.busTurn.BscCapacity) -  parseInt(resp.data.Quantity);
          }
        })
      }
    },

    registerEntryPersonBus()
    {
      this.registerPersonBus.BtrID = this.busTurn.BtrID;
      
      this.registerPersonBus.SecStatus = 1;
      this.registerPersonBus.UsrCreateID = this.$fun.getUserID();
      this.registerPersonBus.UsrUpdateID = this.$fun.getUserID();
      this.registerPersonBus.EpbLatitude = this.latitud.toString();
			this.registerPersonBus.EpbLongitude = this.longitud.toString();

      console.log('aaaaaaaaaaa ', this.registerPersonBus);

      _sHmnBusTurnService
      .saveentrypersonbus(this.registerPersonBus, this.$fun.getUserID())
      .then(resp => {
        if(resp.status == 200)
        {
          if(resp.data.EpbID != 0)
          {
            this.getQuantityPersonEntryByTurn();
            this.person.color = "success";
          }
          
        }
      })
      .catch(error => {
        this.person.MahMessage = error.response.data.Message;
        this.person.color = "red";
      })
    },

    verifyTurnActiveByDriver()
    {
      
      let obj = {
        BscDniDriver : this.$fun.getUserInfoLogin()
      }
      _sHmnBusTurnService
      .busturnactive(obj, this.$fun.getUserID())
      .then(resp => {
        if(resp.status == 200){
          
          if(resp.data == null)
          {
            this.isActiveTurn = false;
          }
          else{
            this.busTurn = resp.data;
          }
          
        }
      })

      return this.isActiveTurn;
    },

    start(pv) {
      this.createBusTurn(pv);
      this.getQuantityPersonEntryByTurn();
      if (pv != null) {
        this.$fun.fullScreen();
        this.pv = pv;
        /* this.capacity = pv.DedHelper;
        this.pv.placa = pv.placa; */
        this.person.PstName = this.$moment().format("DD/MM/YYYY");
        this.dialog = false;
      }
      //console.log(this.$refs.p.$refs.text.$refs);
    },
    cancel() {
      this.$router.go(-1);
    },
    clickBack() {
      this.$refs.textQR.focus();
    },
    clickCamera() {
      this.dialogCamera = true;
      this.getLocation();
    },
    access() {
      this.registerPersonBus.EpbDocumentNumber = this.document;
      if (this.document.length > 3) {
        ControlAccessService.personaccess(
          this.document,
          this.$fun.getUserID(),
          7,
          1
        ).then((r) => {
          this.document = "";
          this.person = r.data;
          if (this.person.TypeError == 0) {
            //this.person.color = "success";
            this.registerEntryPersonBus();
          }
          else if (this.person.TypeError == 2) {
            this.person.color = "warning";
            this.$fun.soundAlert();
          } else if (this.person.TypeError == 1) {
            this.person.color = "red";
            this.$fun.soundAlert();
          }

          HelperService.getPhotoWorker(
            r.data.PrsDocumentNumber,
            this.$fun.getUserID()
          ).then((rsp) => {
            const blob = new Blob([rsp.data], {
              type: "image/jpg",
            });
            let meal = r.data;
            let image = blob;
            var reader = new FileReader();
            reader.readAsDataURL(image);
            reader.onloadend = () => {
              this.image = reader.result;
            };
          });
        });
      }
    },
    onValue(val) {
      if (val == "Sin datos" || isNaN(val)) {
        this.$fun.alert("El QR Leido no contiene datos", "warning");
      } else {
        this.document = val;
        this.getLocation();
        this.access();
      }
    },

    onData(val) {
      /* let valarray = val.split(",");
      console.log("🚀 ~ file: WashDisinfect.vue ~ line 259 ~ valarray", valarray) */
    },

    getRouteByDriver()
    {
      let obj = {
        BscDniDriver :  this.$fun.getUserInfoLogin().toString()
      }
      _smonitoringBusService
      .search(obj, this.$fun.getUserID())
      .then(resp => {
        if(resp.status == 200)
        {
          if(resp.data != null)
          {
            this.capacity = resp.data.BscCapacity;
            this.pv = resp.data;
            this.isActiveRoute = true;
            /* this.verifyTurnActiveByDriver();
            this.start(this.pv); */
          }
          else
          {
            this.$fun.alert('No tiene una ruta asignada', 'warning');
          }
        }
      })

      return this.isActiveRoute;
    },

    initialize()
    {
      if(this.getRouteByDriver())
      {
        if(this.verifyTurnActiveByDriver())
        {
          this.existBusTurn = true;
        }
        else
        {
          this.createBusTurn({});
          this.existBusTurn = false;
        }
      }
    }
  },

  created () {
   
    this.initialize();

    //this.getRouteByDriver();

   /*  let obj = {
        BscDniDriver : this.$fun.getUserInfoLogin()
      }
    _sHmnBusTurnService
    .busturnactive(obj, this.$fun.getUserID())
    .then(resp => {
      if(resp.status == 200){
        this.busTurn = resp.data;
        if(this.busTurn != null)
        {
          this.existBusTurn = true;
        }
        else{
          this.createBusTurn({});
        }
        this.getQuantityPersonEntryByTurn();
      }
    }) */
  },
};
</script>
<style scope>
.inputControl input {
  font-size: 32px !important;
}

.background {
  background-repeat: no-repeat; /* Indicamos que no se repetira */
  background-size: cover !important; /* Encajamos la imagen al 100% del ancho */
  background-attachment: fixed !important; /* Establecemos una posicion fija para la imagen */
}
</style>
